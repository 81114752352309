import {
  USDC_DECIMALS,
  USDT_DECIMALS,
  WBTC_DECIMALS,
  WEI_DECIMALS,
} from '@lyra/core/constants/contracts'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import { Address } from 'viem'

import { DepositNetwork } from './chains'
import { isMainnet, isTestnet } from './env'
import { MarketId } from './markets'
import { EBTC_DEPOSIT_ADDRESS_ALLOWLIST } from './walletLists'

// Supported Lyra Exchange collateral
export enum CollateralId {
  USDC = 'USDC',
  ETH = 'ETH',
  WSTETH = 'WSTETH',
  BTC = 'BTC',
  DAI = 'DAI',
  SDAI = 'SDAI',
  USDE = 'USDE',
  SUSDE = 'SUSDE',
  USDT = 'USDT',
  WEETH = 'WEETH',
  RSWETH = 'RSWETH',
  RSETH = 'RSETH',
  LBTC = 'LBTC',
  EBTC = 'EBTC',
  CBBTC = 'CBBTC',
}

// Lyra chain ERC20
export enum TokenId {
  USDC = 'USDC',
  ETH = 'ETH',
  WSTETH = 'wstETH',
  WBTC = 'WBTC',
  DAI = 'DAI',
  SDAI = 'SDAI',
  USDE = 'USDE',
  SUSDE = 'SUSDE',
  USDT = 'USDT',
  WEETH = 'WEETH',
  RSWETH = 'RSWETH',
  RSETH = 'RSETH',
  LBTC = 'LBTC',
  EBTC = 'EBTC',
  CBBTC = 'CBBTC',
}

// Deposit tokens
export enum DepositTokenId {
  USDC = 'USDC',
  USDCe = 'USDC.e',
  USDT = 'USDT',
  ETH = 'ETH',
  WETH = 'WETH',
  WSTETH = 'wstETH',
  WBTC = 'WBTC',
  WEETH = 'WEETH',
  LBTC = 'LBTC',
  RSWETH = 'RSWETH',
  RSETH = 'RSETH',
  DAI = 'DAI',
  SDAI = 'SDAI',
  USDE = 'USDE',
  SUSDE = 'SUSDE',
  EBTC = 'EBTC',
  CBBTC = 'CBBTC',
}

// Currencies can be markets (e.g. SOL) or collaterals (e.g. LBTC)
export type CurrencyId = MarketId | CollateralId

// TODO: @earthtojake use PartialToken for all token address configs
export type PartialToken = {
  address: Address
  symbol: string
  decimals: number
}

export type TokenConfig = {
  symbol: string
  name: string
  tokenId: TokenId
  marketId?: MarketId
  collateralId: CollateralId
  isDollarFormat: boolean
  decimals: number
  isActive: boolean
  isVaultOnly?: boolean
  isSpotActive: boolean
  isUnlisted?: boolean
}

export type CollateralData = {
  collateralId: CollateralId
  spotPrice: number
  spotPrice24hAgo: number
  spotPrice24hChange: number
  spotPrice24hPctChange: number
}

export type Collateral = CollateralData & TokenConfig

export const tokenConfig: Record<TokenId, TokenConfig> = {
  [TokenId.ETH]: {
    symbol: 'ETH',
    name: 'Ethereum',
    tokenId: TokenId.ETH,
    collateralId: CollateralId.ETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    isSpotActive: true,
  },
  [TokenId.WBTC]: {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    tokenId: TokenId.WBTC,
    collateralId: CollateralId.BTC,
    isDollarFormat: false,
    decimals: WBTC_DECIMALS,
    isActive: true,
    isSpotActive: isMainnet,
  },
  [TokenId.USDC]: {
    symbol: 'USDC',
    name: 'USD Coin',
    tokenId: TokenId.USDC,
    collateralId: CollateralId.USDC,
    isDollarFormat: true,
    decimals: USDC_DECIMALS,
    isActive: true,
    isSpotActive: false,
  },
  [TokenId.USDT]: {
    symbol: 'USDT',
    name: 'Tether USDt',
    tokenId: TokenId.USDT,
    collateralId: CollateralId.USDT,
    isDollarFormat: true,
    decimals: USDT_DECIMALS,
    isActive: true,
    isSpotActive: false,
  },
  [TokenId.WSTETH]: {
    symbol: 'wstETH',
    name: 'Wrapped stETH',
    tokenId: TokenId.WSTETH,
    collateralId: CollateralId.WSTETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    isSpotActive: isMainnet,
  },
  [TokenId.WEETH]: {
    symbol: 'weETH',
    name: 'Wrapped eETH',
    tokenId: TokenId.WEETH,
    collateralId: CollateralId.WEETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    isVaultOnly: true,
    isSpotActive: isTestnet,
  },
  [TokenId.RSWETH]: {
    symbol: 'rswETH',
    name: 'Restaked Swell ETH',
    tokenId: TokenId.RSWETH,
    collateralId: CollateralId.RSWETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    isVaultOnly: true,
    isSpotActive: isTestnet,
  },
  [TokenId.RSETH]: {
    symbol: 'rsETH',
    name: 'Kelp ETH',
    tokenId: TokenId.RSETH,
    collateralId: CollateralId.RSETH,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    isVaultOnly: true,
    isSpotActive: isTestnet,
  },
  [TokenId.DAI]: {
    symbol: 'DAI',
    name: 'DAI',
    tokenId: TokenId.DAI,
    collateralId: CollateralId.DAI,
    isDollarFormat: true,
    decimals: WEI_DECIMALS,
    isActive: true,
    isSpotActive: false,
  },
  [TokenId.SDAI]: {
    symbol: 'sDAI',
    name: 'Savings DAI',
    tokenId: TokenId.SDAI,
    collateralId: CollateralId.SDAI,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    isSpotActive: isMainnet,
  },
  [TokenId.USDE]: {
    symbol: 'USDe',
    name: 'Ethena USDe',
    tokenId: TokenId.USDE,
    collateralId: CollateralId.USDE,
    isDollarFormat: true,
    decimals: WEI_DECIMALS,
    isActive: true,
    isSpotActive: false,
  },
  [TokenId.SUSDE]: {
    symbol: 'sUSDe',
    name: 'Ethena Staked USDe',
    tokenId: TokenId.SUSDE,
    collateralId: CollateralId.SUSDE,
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    isActive: true,
    isSpotActive: isTestnet,
  },
  [TokenId.LBTC]: {
    symbol: 'LBTC',
    name: 'Lombard Staked Bitcoin',
    tokenId: TokenId.LBTC,
    collateralId: CollateralId.LBTC,
    isDollarFormat: false,
    decimals: 8,
    isActive: true,
    isSpotActive: false,
  },
  [TokenId.CBBTC]: {
    symbol: 'cbBTC',
    name: 'Coinbase Wrapped BTC',
    tokenId: TokenId.CBBTC,
    collateralId: CollateralId.CBBTC,
    isDollarFormat: false,
    decimals: 8,
    isActive: true,
    isSpotActive: isTestnet,
  },
  [TokenId.EBTC]: {
    symbol: 'eBTC',
    name: 'Ether.fi Bitcoin',
    tokenId: TokenId.EBTC,
    collateralId: CollateralId.EBTC,
    isDollarFormat: false,
    decimals: 8,
    isActive: true,
    isUnlisted: true,
    isSpotActive: false,
  },
}

export const collateralConfig: Record<CollateralId, TokenConfig> = {
  [CollateralId.ETH]: tokenConfig[TokenId.ETH],
  [CollateralId.BTC]: tokenConfig[TokenId.WBTC],
  [CollateralId.USDC]: tokenConfig[TokenId.USDC],
  [CollateralId.USDT]: tokenConfig[TokenId.USDT],
  [CollateralId.WSTETH]: tokenConfig[TokenId.WSTETH],
  [CollateralId.WEETH]: tokenConfig[TokenId.WEETH],
  [CollateralId.RSWETH]: tokenConfig[TokenId.RSWETH],
  [CollateralId.RSETH]: tokenConfig[TokenId.RSETH],
  [CollateralId.DAI]: tokenConfig[TokenId.DAI],
  [CollateralId.SDAI]: tokenConfig[TokenId.SDAI],
  [CollateralId.USDE]: tokenConfig[TokenId.USDE],
  [CollateralId.SUSDE]: tokenConfig[TokenId.SUSDE],
  [CollateralId.LBTC]: tokenConfig[TokenId.LBTC],
  [CollateralId.CBBTC]: tokenConfig[TokenId.CBBTC],
  [CollateralId.EBTC]: tokenConfig[TokenId.EBTC],
}

export type DepositTokenConfig = {
  symbol: string
  name: string
  l2MinSponsoredAmount: bigint
  l1MinSponsoredAmount: bigint
  isDollarFormat: boolean
  decimals: number
  tokenConfig: TokenConfig
  isActive: boolean
  hasApy?: boolean
  gelatoNetworks?: DepositNetwork[]
  allowlist?: Set<Address>
}

export const depositTokenConfig: Record<DepositTokenId, DepositTokenConfig> = {
  [DepositTokenId.WETH]: {
    symbol: 'WETH',
    name: 'Wrapped Ether',
    l2MinSponsoredAmount: toBigNumber(0.005, WEI_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.5, WEI_DECIMALS),
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.ETH],
    isActive: true,
    gelatoNetworks: [DepositNetwork.Arbitrum],
  },
  [DepositTokenId.ETH]: {
    symbol: 'ETH',
    name: 'Ethereum',
    l2MinSponsoredAmount: toBigNumber(0.005, WEI_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.5, WEI_DECIMALS),
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.ETH],
    isActive: true,
  },
  [DepositTokenId.USDC]: {
    symbol: 'USDC',
    name: 'USD Coin',
    l2MinSponsoredAmount: toBigNumber(10, USDC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(1000, USDC_DECIMALS),
    isDollarFormat: true,
    decimals: USDC_DECIMALS,
    tokenConfig: tokenConfig[TokenId.USDC],
    isActive: true,
    gelatoNetworks: isMainnet
      ? [DepositNetwork.Ethereum, DepositNetwork.Arbitrum, DepositNetwork.Base]
      : [DepositNetwork.Ethereum, DepositNetwork.Arbitrum],
  },
  [DepositTokenId.USDCe]: {
    symbol: 'USDC.e',
    name: 'USD Coin Bridged',
    l2MinSponsoredAmount: toBigNumber(10, USDC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(1000, USDC_DECIMALS),
    isDollarFormat: true,
    decimals: USDC_DECIMALS,
    tokenConfig: tokenConfig[TokenId.USDC],
    isActive: true,
    gelatoNetworks: isMainnet ? [DepositNetwork.Arbitrum] : undefined,
  },
  [DepositTokenId.WBTC]: {
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    l2MinSponsoredAmount: toBigNumber(0.0002, WBTC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.02, WBTC_DECIMALS),
    isDollarFormat: false,
    decimals: WBTC_DECIMALS,
    tokenConfig: tokenConfig[TokenId.WBTC],
    isActive: true,
    gelatoNetworks: isMainnet ? [DepositNetwork.Arbitrum] : undefined,
  },
  [DepositTokenId.USDT]: {
    symbol: 'USDT',
    name: 'Tether USDt',
    l2MinSponsoredAmount: toBigNumber(10, USDT_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(1000, USDT_DECIMALS),
    isDollarFormat: true,
    decimals: USDT_DECIMALS,
    tokenConfig: tokenConfig[TokenId.USDT],
    isActive: true,
    gelatoNetworks: isMainnet ? [DepositNetwork.Arbitrum] : undefined,
  },
  [DepositTokenId.WSTETH]: {
    symbol: 'wstETH',
    name: 'Wrapped Staked ETH',
    l2MinSponsoredAmount: toBigNumber(0.005, WEI_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.5, WEI_DECIMALS),
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.WSTETH],
    isActive: true,
    hasApy: true,
  },
  [DepositTokenId.WEETH]: {
    symbol: 'weETH',
    name: 'Wrapped eETH',
    l2MinSponsoredAmount: toBigNumber(0.005, WEI_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.5, WEI_DECIMALS),
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.WEETH],
    isActive: true,
  },

  [DepositTokenId.RSWETH]: {
    symbol: 'rswETH',
    name: 'Restaked Swell ETH',
    l2MinSponsoredAmount: toBigNumber(0.005, WEI_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.5, WEI_DECIMALS),
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.RSWETH],
    isActive: true,
  },
  [DepositTokenId.RSETH]: {
    symbol: 'rsETH',
    name: 'Kelp Restaked ETH',
    l2MinSponsoredAmount: toBigNumber(0.005, WEI_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.5, WEI_DECIMALS),
    isDollarFormat: false,
    decimals: WEI_DECIMALS,
    tokenConfig: tokenConfig[TokenId.RSETH],
    isActive: true,
  },
  [DepositTokenId.DAI]: {
    decimals: 18,
    symbol: 'DAI',
    name: 'DAI',
    l2MinSponsoredAmount: toBigNumber(10, USDC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(1000, USDC_DECIMALS),
    isDollarFormat: true,
    tokenConfig: tokenConfig[TokenId.DAI],
    isActive: true,
  },
  [DepositTokenId.SDAI]: {
    decimals: 18,
    symbol: 'sDAI',
    name: 'Savings Dai',
    l2MinSponsoredAmount: toBigNumber(10, USDC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(1000, USDC_DECIMALS),
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.SDAI],
    isActive: true,
  },
  [DepositTokenId.USDE]: {
    decimals: 18,
    symbol: 'USDe',
    name: 'Ethena USDe',
    l2MinSponsoredAmount: toBigNumber(10, USDC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(1000, USDC_DECIMALS),
    isDollarFormat: true,
    tokenConfig: tokenConfig[TokenId.USDE],
    isActive: true,
  },
  [DepositTokenId.SUSDE]: {
    decimals: 18,
    symbol: 'sUSDe',
    name: 'Ethena Staked USDe',
    l2MinSponsoredAmount: toBigNumber(10, USDC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(1000, USDC_DECIMALS),
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.SUSDE],
    isActive: true,
  },
  [DepositTokenId.LBTC]: {
    decimals: 8,
    symbol: 'LBTC',
    name: 'Lombard Staked Bitcoin',
    l2MinSponsoredAmount: toBigNumber(0.0002, 8),
    l1MinSponsoredAmount: toBigNumber(0.02, 8),
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.LBTC],
    isActive: true,
  },
  [DepositTokenId.CBBTC]: {
    decimals: 8,
    symbol: 'cbBTC',
    name: 'Coinbase Wrapped BTC',
    l2MinSponsoredAmount: toBigNumber(0.0002, 8),
    l1MinSponsoredAmount: toBigNumber(0.02, 8),
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.CBBTC],
    isActive: true,
  },
  [DepositTokenId.EBTC]: {
    decimals: 8,
    symbol: 'eBTC',
    name: 'Ether.fi Bitcoin',
    l2MinSponsoredAmount: toBigNumber(0.0002, WBTC_DECIMALS),
    l1MinSponsoredAmount: toBigNumber(0.02, WBTC_DECIMALS),
    isDollarFormat: false,
    tokenConfig: tokenConfig[TokenId.EBTC],
    isActive: true,
    allowlist: EBTC_DEPOSIT_ADDRESS_ALLOWLIST,
  },
}

export type WithdrawNetworkToken = {
  network: DepositNetwork
  token: TokenId
  withdrawToken: DepositTokenId
}

export const MINT_TESTNET_USDC_AMOUNT = BigInt(100_000_000000) // 100k USDC minted
export const MAX_TESTNET_USDC_AMOUNT = 500_000 // 500k USDC max per subaccount

// Note: this is a mock address for native ETH
export const ETH_ADDRESS: Address = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'

export type DepositTokenAddresses = Record<string, Record<DepositTokenId, Address | undefined>>
export type TokenAddresses = Record<TokenId, Address | undefined>

export const DEFAULT_WITHDRAW_NETWORK_TOKEN: WithdrawNetworkToken = {
  network: DepositNetwork.Ethereum,
  token: TokenId.USDC,
  withdrawToken: DepositTokenId.USDC,
}

export const EMPTY_COLLATERALS: Record<CollateralId, Collateral> = Object.values(
  CollateralId
).reduce(
  (acc, collateralId) => {
    const collatData: Collateral = {
      ...collateralConfig[collateralId],
      spotPrice: 0,
      spotPrice24hAgo: 0,
      spotPrice24hChange: 0,
      spotPrice24hPctChange: 0,
    }
    acc[collateralId] = collatData
    return acc
  },
  {} as Record<CollateralId, Collateral>
)

export const getEmptyCollaterals = <T>(emptyValue: T): Record<CollateralId, T> =>
  Object.values(CollateralId).reduce(
    (acc, token) => {
      acc[token] = emptyValue
      return acc
    },
    {} as Record<CollateralId, T>
  )
