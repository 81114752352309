import { Address, getAddress } from 'viem'

/**
 * These wallets should be those that are EOAs but should be given rewards as if they were SCWs
 */
export const TRADING_REWARDS_EOA_ADDRESSES: Set<Address> = new Set(
  [
    '0x04BD82Bf46de00a048d66E9Eb00C5Bc28A07Cfb5',
    '0x5330Ac4b4D5d9d800F2e20A55E4F38e60980Da37',
    '0x9Af4C7BeE0b0eB464E16e6A45685237e7840C5Fc',
  ].map((address) => getAddress(address))
)

/**
 * these wallets can be scws or eoas, depending on what is registered with the orderbook
 */
// TODO: '0xb4c4BbD9974D7F4D6e081A7f9aA1f018F032818',
export const TRADING_REWARDS_IGNORE_ADDRESSES: Set<Address> = new Set(
  [
    '0x00395dd7e0480a8e38b9237693603994e5112ce9',
    '0x019fdd3a448f72eca828a35eb35edfd8c0e86cfb',
    '0x03f4e1646a15b888d9e351efe34214cb2d7cbfb1',
    '0x0bd5683e33329c1638be89fc3b983f1820f982be',
    '0x1a41c26ac766d68e269196965fa8be6739787b63',
    '0x1f1e1c9ff993eefe230cabae91dc5c5a73326251',
    '0x22e6164e20f40300c670db998b332e66e60f1e8a',
    '0x2a7d03c5891cb08ddad1e811131eef8401099d22',
    '0x2ad91a2ee1a1210077e39e41de321752c1e9c35a',
    '0x2fb6316844f241a63a20e6b5228462041eabe4ee',
    '0x39e57aa879420c53f5cf0a0c66b95fe45ebcb36a',
    '0x3a5c777edf22107d7fdfb3b02b0cdfe8b75f3453',
    '0x3ed4ed6056c8e3549bb873ca964c060bb40b8ac0',
    '0x43a9880da38446a6cc589be9070e90a1e5d17ed7',
    '0x4925a1bd407c9e2a877b387c65bff189503ba46e',
    '0x49391e87309ed2dcf0e3ecef117f367e710bbefe',
    '0x4c7e2cbb3938ab31701416edcdaefb14b10fba47',
    '0x4dacb27add080d5cb4842f6a036a3103a85de9a3',
    '0x55b3d64b865509a9bce4956f5e21b25e91958ddb',
    '0x600158d017c3874a7fa9fc7954dbaa3599a3ca42',
    '0x6425f64a067c184e198a4b18e25bb821eac49282',
    '0x6b0fe721937ed61c6e261b5053d6178ecef059a4',
    '0x6b6a147bdd02bfd02e485c306c93c5e5d0901d4a',
    '0x6dadefcede8d12b8e46707a0a6737c6c3688af26',
    '0x772667a641a0606b47d93c765f4ec1302b5fc11b',
    '0x787cbf841f03cdd5dcdd9e6dc3f2d1f0b6f999da',
    '0x7b7e1b95ee230a0a2774c14d529236cf4000bf07',
    '0x7d792d96b45f6901a17433243bf8e6514f946e42',
    '0x80dca3125961abd9d6e6b62b488fa3c6d6cfff2e',
    '0x8358d0e1468dd370cdaddac1ec1f1b85dfb675bc',
    '0x8be891689037c58c425edc76dbf3b62451304dd1',
    '0x8c0066247e6e6421873f2dfeacd7a9e3bcfac5a8',
    '0x978e3eb7997a9575cde1799176829073d2876fb9',
    '0x98d094a8ce25c45c01d017cd98fbad9807b163a4',
    '0x9e8edbf9da32c33f8d1ed3f1fc28e9f952fde66c',
    '0xa0b4ed5ccfc91ef092636aa33807962fc3ace05c',
    '0xa2cd2504e7b7cf491d63adbc587d6a859c8bda20',
    '0xae0bc841b02536f56ff47e1396dee17fd97839ad',
    '0xb8382ef2f7a9a0471186fc969d25bb08ed8bc91b',
    '0xb85e71c7d386771320a0c2051c4d7e9269ff0700',
    '0xbbbc0fe4408acb8b9c8c6cc782c93ba8c791423d',
    '0xbfa7a2cd6ccd7ef8e77aa2181631b2abe61676f5',
    '0xc12da78457783416a689824802ddd3155e945d12',
    '0xe1c918c6e4ba3efcc9608333b321e0764b4612dd',
    '0xe1dc5576b36c78c75e6abf2e1b121db20a6069b7',
    '0xe2657349db652953f2b05b092c59373200132d02',
    '0xe7f220648b3424688201fad5f48d924de0baecba',
    '0xeac2374016613765cf31afdb4aeb6f5d13a63726',
    '0xf248e91e8696dd286d492d907f725630e847fbce',
    '0xf3c69f47fb504027725d3dc75db819997f13327f',
    '0xf7936fc302849a88e454d0c311c676c7f449821a',
    '0x88B6BB87fbFac92a34F8155aaA35c87B5b166fA9',
  ].map((address) => getAddress(address))
) // checksum

export const EBTC_DEPOSIT_ADDRESS_ALLOWLIST: Set<Address> = new Set(
  [
    '0x9cda2eb97A500c16F660F8a79126874c021bbb49',
    '0x2A5e5e23fdd0868D0c8c78Ddcf2958393bC900EE',
    '0xea21ed29F4339A5B7eEe409974e3E7c86404C85C',
  ].map((address) => getAddress(address))
) // checksum

export const VAULT_REWARDS_IGNORE_ADDRESSES: Set<Address> = new Set(
  [
    '0xADEEb9d09B8Bcee10943198FB6F6a4229bAB3675',
    '0xca8e0f1Be1ceFAe30Cfd21aFe34C82f7B541097F',
    '0x59eD948390F079F2534C052acc3419d34975E026',
    '0xe8a280E6b5213E8Ccd6de4Fc4B50911De749E551',
    '0x5a54aD9860B08AAee07174887f9ee5107b0A2e72',
    '0xCdCa9eDDD55b5863bC55253F5A5C1d7B3cDcF121',
    '0x74c77C2A5D0A2aA6A99b757B00CBD61C67dbD1c6',
    '0x825F23921dCFf36944d8B0b0CA23ac7D05fB86cB',
    '0xc6dD9976066F3364b4D6A72cD4F1fA0468327Aa7',
    '0xDAb1B36BBa5D7baaD1a56aF401FDaa79a7fa8b70',
    '0x89FEe8E11E2A238579fB0d59f5CE1DD90C85FE15',
    '0x74630370197b4c4795bFEeF6645ee14F8cf8997D',
    '0x8A25d8C9fa8C7A726137f2D618d85CbC2C083F78',
    '0x31d3243cfb54b34fc9c73e1cb1137124bd6b13e1',
    '0x976D424Bb3070B9558b7CF9Ea5C0b746281AE75E',
  ].map((address) => getAddress(address))
) // checksum
